import React from 'react';
import { Router, Location } from '@reach/router';
import Loadable from 'react-loadable';
import Loading from 'components/loading.js';
import PrivateRoute from 'routes/PrivateRoute';
import routes from 'routes/routes';

const LoadableLayout = Loadable({
  loader: () => import('layouts/Admin'),
  loading: Loading
});

const LoadableComponent = (component) => {
  return Loadable({
    loader: () => component,
    loading: Loading
  });
};

const AdminClient = () => (
  <Location>
    {({ location }) => (
      <LoadableLayout>
        <Router basepath="/admin" location={location}>
          { routes.map((x, key) => <PrivateRoute key={key} component={LoadableComponent(x.component)} path={ x.path } />) }
        </Router>
      </LoadableLayout>
    )}
  </Location>
);

export default AdminClient;
